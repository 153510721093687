import React from 'react';
import { SxStyleProp } from 'theme-ui';

import {
  ComponentProps,
  Icon,
  IconProps,
  Label,
} from '@Components';
import {
  BadgeSize,
  BadgeVariant,
  LabelVariants,
  IncrementSizes,
  SpacerSizes,
} from '@Tokens';
import { Responsive, createResponsiveConfig } from '@Utils';

const variantStyles: Record<BadgeVariant, SxStyleProp> = {
  Neutral: {
    backgroundColor: 'backgroundLightsubtle',
  },
  NeutralInverted: {
    backgroundColor: 'backgroundDark',
    color: 'textLight',
  },
  Informative: {
    backgroundColor: 'backgroundInformationlight',
  },
  InformativeInverted: {
    backgroundColor: 'backgroundInformationdark',
    color: 'textLight',
  },
  Success: {
    backgroundColor: 'backgroundSuccesslight',
  },
  SuccessInverted: {
    backgroundColor: 'backgroundSuccessdark',
    color: 'textLight',
  },
  Warning: {
    backgroundColor: 'backgroundWarningmedium',
  },
  WarningInverted: {
    backgroundColor: 'backgroundWarningdark',
    color: 'textLight',
  },
  Critical: {
    backgroundColor: 'backgroundCriticallight',
  },
  CriticalInverted: {
    backgroundColor: 'backgroundCriticaldark',
    color: 'textLight',
  },
  PrimaryYellow: {
    backgroundColor: 'baseYellowlight',
  },
  PrimaryYellowInverted: {
    backgroundColor: 'baseYellow',
  },
  PrimaryBlue: {
    backgroundColor: 'baseSecondary',
  },
  PrimaryBlueInverted: {
    backgroundColor: 'basePrimary',
    color: 'textLight',
  },
  NeutralTransparent: {
    backgroundColor: 'backgroundNone',
  },
  NeutralInvertedTransparent: {
    backgroundColor: 'backgroundNone',
    color: 'textLight',
  },
  Tag: {
    backgroundColor: 'tagprimaryDefault',
    color: 'textLight',
  },
};

export interface BadgeProps extends ComponentProps {
  variant: BadgeVariant;
  size: Responsive<BadgeSize>;
  icon?: IconProps['name'];
  iconPosition?: 'left' | 'right';
}

type BadgeConfig = Record<BadgeSize, {
  labelVariant: LabelVariants;
  paddingY: keyof SpacerSizes;
  iconSize: keyof IncrementSizes;
}>;

const badgeConfig = createResponsiveConfig<BadgeConfig>({
  24: {
    labelVariant: 'small',
    paddingY: '4xs',
    iconSize: '16',
  },
});

/*
* @deprecated: Migrate to BadgeInformative
*/
export const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = ({
  className,
  'data-id': dataId,
  children,
  variant,
  size,
  icon,
  iconPosition,
}) => {
  const { labelVariant, paddingY, iconSize } = badgeConfig.get(size);

  return (
    <Label
      className={className}
      data-id={dataId}
      variant={labelVariant}
      sx={{
        whiteSpace: 'nowrap',
        paddingX: '3xs',
        paddingY,
        borderRadius: 'rounded',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '4xs',
        ...(variantStyles[variant]),
      }}
    >
      {iconPosition === 'left' && icon && (
        <Icon
          size={iconSize}
          name={icon}
        />
      )}
      {children}
      {iconPosition === 'right' && icon && (
        <Icon
          size={iconSize}
          name={icon}
        />
      )}
    </Label>
  );
};
