import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { Label, TriggerButton } from '@Components';
import type { ButtonProps, TriggerButtonProps, ComponentProps } from '@Components';

export interface PillSelectorProps extends
  Omit<ComponentProps, 'as'>, Pick<ButtonProps, 'as'> {
  label: string;
  selected?: boolean;
  disabled?: TriggerButtonProps['disabled'];
  onTrigger: TriggerButtonProps['onTrigger'];
  /**
   * Whether it should extend to 100% width of parent, default to false
   */
  stretch?: boolean;
}

export const PillSelector: React.FC<React.PropsWithChildren<PillSelectorProps>> = ({
  label,
  selected = false,
  disabled = false,
  onTrigger,
  stretch = false,
  ...restProps
}) => {
  const highlightedStyles: SxStyleProp = {
    backgroundColor: 'backgroundPrimarylight',
    borderColor: 'backgroundPrimarylight',
  };

  return (
    (
      <TriggerButton
        disabled={disabled}
        onTrigger={onTrigger}
        {...restProps}
        sx={{
          ...(stretch && { width: '100%' }),
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingY: '3xs',
          paddingX: '2xs',
          border: 'solid',
          borderWidth: 'outlinedStrokeWeight',
          borderRadius: 'rounded',
          borderColor: !disabled ? 'strokeDarkneutral' : 'strokeDisabledlight',
          color: !disabled ? 'textDefault' : 'textDisabled',
          ...(selected && {
            backgroundColor: 'backgroundBlack',
            borderColor: 'backgroundBlack',
            color: 'textLight',
          }),
          '&:hover, &:focus': {
            ...((!selected && !disabled) && highlightedStyles),
          },
        }}
      >
        <Label
          variant="small"
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {label}
        </Label>
      </TriggerButton>
    )
  );
};
